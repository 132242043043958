body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
}

body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  background-color: #f4f4f4;
  color: #333;
}

/* Header Styling */
header {
  background-color: #333;
  color: #fff;
  padding: 20px 0;
  margin-bottom: 20px;
}

nav {
  background: #0082e6;
  height: 80px;
  width: 100%;
}

label.logo {
  color: white;
  font-size: 28px;
  line-height: 80px;
  padding: 0 100px;
  font-weight: bold;
}

nav ul {
  float: right;
  margin-right: 20px;
}

nav ul li {
  display: inline-block;
  line-height: 60px;
  margin: 0 15px;
}

nav ul li a {
  color: white;
  font-size: 17px;
  text-transform: uppercase;
  padding: 7px 13px;
  border-radius: 4px;
}

a.active, a:hover {
  background: #1b9bff;
  transition: .5s;
}

.checkbtn {
  font-size: 30px;
  color: white;
  float: right;
  line-height: 80px;
  margin-right: 40px;
  cursor: pointer;
  display: none;
}
#check {
  display: none;
}

header h1 {
  margin-bottom: 10px;
}

header nav ul {
  list-style: none;
  display: flex;
  justify-content: center;
  gap: 15px;
}

header nav ul li {
  display: inline;
  font-color: #000;
}

header nav ul li a {
  color: #fff;
  text-decoration: none;
  padding: 5px 10px;
  transition: background-color 0.3s;
}

header nav ul li a:hover {
  background-color: #575757;
  border-radius: 5px;
}

/* Main Content Styling */
.container {
  width: 750px;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h2 {
  margin-bottom: 15px;
  color: #333;
  border-bottom: 2px solid #333;
  padding-bottom: 5px;
}

ul, ol {
  margin: 10px 0 20px 20px;
}

pre {
  background-color: #f4f4f4;
  padding: 15px;
  border-left: 3px solid #333;
  overflow-x: auto;
}

code {
  font-family: 'Courier New', Courier, monospace;
}

.next-page-btn {
  width: 100px;
  height: 50px;
  background-color: #3480cc;
  border-radius: 8px;
  border: none;
  margin: 20px;
  margin-left: 72%;
  cursor: pointer;
}

/* Footer Styling */
footer {
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 15px 0;
  margin-top: 20px;
}

footer a {
  color: #fff;
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}

footer p {
  color: #fff;
}


@media (max-width: 950px){
  label.logo {
    font-size: 30px;
    padding-left: 50px;
  }

  nav ul li a {
    font-size: 16px;
  }
  .container {
    width: 80%;
  }
}

@media (max-width: 850px){
  .checkbtn {
    display: block;
  }
  ul {
    position: fixed;
    width: 100%;
    height: 50vh;
    background: #2c3e50;
    top: 80px;
    left: -100%;
    text-align: center;
    transition: all .5s;
  }

  nav ul li {
    display: block;
    margin: 50px 0;
    line-height: 30px;
  }

  nav ul li a {
    font-size: 20px;
  }
  a:hover, a.active {
    background: none;
    color: #0082e6;
  }
  .container {
    width: 80%;
  }

  #check:checked ~ ul {
    left: 0;
  }

  .next-page-btn {
    margin-left: 50%;
  }
}